export default {
  siteTitle: 'Get bonus',
  blogerName: 'JESSY',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@maiawin'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@realidadedosslots2'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/jessykawinslot/'
    },
    {
      name: 'whatsapp',
      url: 'https://chat.whatsapp.com/EPR2z8nGhdgDUIUGm46A4Q'
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c105824b0',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c10a9a251',
      gameTitle: 'Alien Fruits (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/cdefe65ed',
      gameTitle: 'Starda Queen (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c1402f87a',
      gameTitle: 'Legzo Punk (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c887d0f9a',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c35818239',
      gameTitle: 'Izzi Art (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c1c97bf8f',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/cdda93985',
      gameTitle: 'Candy Monsta (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>JESSY50</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'JESSY50',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'dark'
};
